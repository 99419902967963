<template>
  <div>
    <div class="font-bold text-lg">Cancel order</div>

    <div class="mt-4 w-full flex flex-col gap-2">
      <div class="flex gap-4">
        <span class="font-semibold">Exchange: </span>
        <span class="font-semibold text-gray-600">{{ order.exchange }}</span>
      </div>
      <div class="flex gap-4">
        <span class="font-semibold">Symbol: </span>
        <span class="font-semibold text-gray-600">{{ order.symbol }}</span>
      </div>
      <div class="flex gap-4">
        <span class="font-semibold">Side: </span>
        <span class="font-semibold text-gray-600">{{ SIDES[order.side] }}</span>
      </div>
      <div class="flex gap-4">
        <span class="font-semibold">Volume: </span>
        <span class="font-semibold text-gray-600">{{ order.volume }}</span>
      </div>
    </div>

    <div class="flex mt-4 w-full justify-end">
      <d-btn color="error" @click="cancel">Cancel</d-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CancelManualOrdermodal',

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    SIDES: {
      1: 'BUY',
      2: 'SELL',
    },
  }),

  methods: {
    async cancel(event) {
      await this.$http.post('/api/v2/manual-orders/cancel', {
        id: this.order._id,
      })
      this.$$closeModal(event)
    },
  },
}
</script>
