import { EXCHANGE_NAMES } from '@/common/constants.js'
import { headerItem } from '@/utils/table'
import { formatNumber, formatDate } from '@/utils/string/index.js'
import Badge from '@/components/daisy/atoms/badge/index.vue'
import Button from '@/components/daisy/atoms/button/index.vue'
import Cryptofonts from '@/components/daisy/atoms/cryptofonts/index.vue'

const DIRECT_ORDER_STATUS = Object.freeze({
  NEW: 101,
  WAITING: 102,
  FILLED: 103,
  CANCELLED: 104,
  FAILED: 105,
})

const SPAN_MAP = {
  30e3: '30s',
  60e3: '1m',
  120e3: '2m',
  300e3: '5m',
  600e3: '10m',
  900e3: '15m',
  3600e3: '1h',
}

const typeMap = {
  [DIRECT_ORDER_STATUS.NEW]: 'info',
  [DIRECT_ORDER_STATUS.WAITING]: 'info',
  [DIRECT_ORDER_STATUS.FILLED]: 'success',
  [DIRECT_ORDER_STATUS.CANCELLED]: 'error',
  [DIRECT_ORDER_STATUS.FAILED]: 'error',
}
const textMap = {
  [DIRECT_ORDER_STATUS.NEW]: 'Open',
  [DIRECT_ORDER_STATUS.WAITING]: 'Open',
  [DIRECT_ORDER_STATUS.FILLED]: 'Filled',
  [DIRECT_ORDER_STATUS.CANCELLED]: 'Cancelled',
  [DIRECT_ORDER_STATUS.FAILED]: 'Error',
}

export default function headers(_this) {
  return [
    global.IS_DEBUG ? headerItem('ID', '_id', {
      generator: (item) => {
        return { value: item._id }
      },
    }) : null,
    headerItem('Account', 'account', {
      class: 'lg:w-48 w-24 overflow-hidden',
      cellClass: 'sticky left-0 font-semibold',
      generator: (item) => {
        return { value: `
          <div>
          ${item?.credential?.name || '-'}
          <br/>
          (${EXCHANGE_NAMES[item.exchange] || '-'})
          </div>
        ` }
      },
    }),
    headerItem('Symbol', 'symbol', {
      generator: (item) => {
        return {
          component: Cryptofonts,
          props: {
            symbol: item.symbol,
          },
        }
      },
    }),
    headerItem('Type', 'type', {
      cellClass: 'font-semibold text-gray-500',
      generator: (item) => {
        let value = _this.$t('Market')
        if (item.stopOrder ) {
          value = _this.$t('Stop')
        }
        if (item.limitOrder ) {
          value = _this.$t('Limit')
        }
        return { value }
      },
    }),
    headerItem('Span', 'span', {
      cellClass: 'font-semibold text-gray-500',
      generator: (item) => {
        return { value: SPAN_MAP[item.span] }
      },
    }),
    headerItem('Side', 'side', {
      cellClass: 'font-semibold text-gray-500',
      generator: (item) => {
        const { side } = item
        return {
          component: Badge,
          props: {
            type: side === 1 ? 'success' : 'error',
            label: side === 1 ? 'Buy' : 'Sell',
          },
        }
      },
    }),
    headerItem('Volume', 'volume', {
      cellClass: 'font-semibold text-gray-500',
      generator: (item) => {
        return {
          value: item.volume > 1 ? formatNumber(item.volume) : item.volume,
        }
      },
    }),
    headerItem('Mark', 'stopPrice', {
      cellClass: 'font-semibold text-gray-500',
      generator: (item) => {
        const price = item.stopPrice || item.limitPrice
        return {
          value: formatNumber(price),
        }
      },
    }),
    headerItem('Price', 'price', {
      cellClass: 'font-semibold text-gray-500',
      generator: (item) => {
        return {
          value: formatNumber(item.price),
        }
      },
    }),
    headerItem('Status', 'status', {
      cellClass: 'font-semibold text-gray-500',
      generator: (item) => {
        const { status } = item
        return {
          component: Badge,
          props: {
            type: typeMap[status] || 'warn',
            label: textMap[status] || 'Unknow',
          },
        }
      },
    }),
    headerItem('Entry / Filled', 'lastUpdate', {
      cellClass: 'font-semibold text-gray-500',
      generator: (item) => {
        const filled = item.status === DIRECT_ORDER_STATUS.FILLED
        const short = global.IS_MOBILE
        return {
          value: `<span>
              ${formatDate(item.createdAt, short)}
              ${filled ? '<br/>' : ''}
              ${filled ? formatDate(item.updatedAt, short) : ''}
            </span>`,
        }
      },
    }),
    headerItem('Actions', 'actions', {
      cellClass: 'font-semibold text-gray-500',
      generator: (item) => {
        const cancelAble = cancelable(item)
        return {
          component: Button,
          props: {
            disabled: !cancelAble,
            text: _this.$t('Cancel'),
            size: 'xs',
          },
          events: {
            click() {
              _this.cancel(item)
            },
          },
        }
      },
    }),
    headerItem('Memo', 'memo', {
      cellClass: 'font-semibold text-gray-500',
      generator: (item) => {
        return { value: item?.memo || '-' }
      },
    }),
  ].filter(Boolean)
}

function cancelable(order) {
  if (order.status === DIRECT_ORDER_STATUS.CANCELLED) {
    return false
  }
  if (order.triggered) {
    return false
  }
  return order.stopOrder || order.limitOrder
}
