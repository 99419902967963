<template>
  <div>
    <div class="mt-4 max-width flex justify-between">
      <div class="flex items-center justify-between space-x-2">
        <span class="font-medium">
          {{ $t('Open Only') }}
        </span>
        <input
          v-model="openOnly"
          type="checkbox"
          checked="checked"
          class="toggle border-solid toggle-primary"
        />
      </div>
      <div class="flex-center space-x-2">
        <account-filter
          :key="key"
          @onChange="(selected) => (accountIds = selected)"
        />
        <button class="ml-auto btn-xs-primary" @click.stop="_loadData">
          {{ $t('Apply') }}
        </button>
        <button
          v-if="accountIds.length > 0"
          class="ml-auto btn-xs-warning"
          @click.stop="reset"
        >
          {{ $t('Reset') }}
        </button>
      </div>
    </div>
    <div class="flex-end w-full mt-4 mb-1">
      <d-paging :total="totalPage" v-model="page" size="xs" />
    </div>
    <d-table
      class="my-4"
      :headers="headers"
      :items="orders"
      compact
      wrapperClasses="min-h-screen overflow-y-scroll"
    />
    <div class="mt-4 max-width flex justify-end">
      <d-paging :total="totalPage" v-model="page" size="xs" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CancelManualOrderModal from '@/components/apps/orders/cancel-manual-order-modal/index.vue'
import _headers from './headers'

export default {
  name: 'manual-orders',

  meta: {
    title: 'Manual Orders',
  },

  data: () => ({
    key: 1,
    openOnly: false,
    page: 1,
    totalPage: 0,
    orders: [],
    accountIds: [],
  }),

  computed: {
    ...mapGetters({
      logics: 'logic/logics',
      accountById: 'credentials/accountMap',
      logicById: 'logic/logicById',
      price: 'market/btcusd',
    }),
    headers() {
      return _headers(this)
    },
  },

  async mounted() {
    await this._loadData()
    this.$watch('page', this._loadData)
    this.$watch('openOnly', () => {
      if (this.page !== 1) {
        this.page = 1
        this.accountIds = []
        this.key = Date.now()
      } else {
        this._loadData()
      }
    })
  },

  methods: {
    async reset() {
      this.key = Date.now()
      this.accountIds = []
      this.page = 1
      if (this.openOnly) {
        this.openOnly = false
      } else {
        await this._loadData()
      }
    },
    async cancel(order) {
      const _loadData = this._loadData
      this.$$showModal({
        component: CancelManualOrderModal,
        props: {
          order,
        },
        events: {
          onClose() {
            _loadData()
          },
        },
      })
    },
    async _loadData() {
      const url = '/api/v2/manual-orders/get'
      const input = {
        page: this.page || 1,
        accountIds: this.accountIds,
        status: this.openOnly ? 101 : undefined,
      }
      if (this.fetchKey === JSON.stringify(input)) {
        return
      }
      const { orders, total } = await this.$http.post(url, input)
      this.fetchKey = JSON.stringify({
        page: this.page || 1,
        accountIds: this.accountIds,
        status: this.openOnly ? 101 : undefined,
      })
      this.totalPage = total
      this.orders = orders.map((el) => {
        el.credential = this.accountById[el.credential]
        return el
      })
    },
  },
}
</script>
